import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { Router } from '@angular/router';
const screenfull = require('screenfull');

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { TranslatorService } from '../../core/translator/translator.service';

import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CommonService } from '@app/core/services/common.service';
import { MultiselectType } from '@app/shared/interfaces/common';
import { RoleCode } from '@app/shared/interfaces/user';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    router: Router;
    selectedLanguage = "";
    role: string;
    companyList = [];

    inputForm: UntypedFormGroup;

    isNavSearchVisible: boolean;
    @ViewChild('fsbutton', { static: true }) fsbutton;  // the fullscreen button

    constructor(public menu: MenuService, public userblockService: UserblockService, public settings: SettingsService,
        public injector: Injector, public translator: TranslatorService, private commonService: CommonService,
        private fb: UntypedFormBuilder, ) {

        // show only a few items on demo
        this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
        this.selectedLanguage = this.translator.getLanguageInUse()

        this.role = localStorage.getItem("role");
    }

    ngOnInit() {
        this.isNavSearchVisible = false;

        var ua = window.navigator.userAgent;
        if (ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./)) { // Not supported under IE
            this.fsbutton.nativeElement.style.display = 'none';
        }

        // Switch fullscreen icon indicator
        /*const el = this.fsbutton.nativeElement.firstElementChild;
        screenfull.on('change', () => {
            if (el)
                el.className = screenfull.isFullscreen ? 'fa fa-compress' : 'fa fa-expand';
        });*/

        this.router = this.injector.get(Router);

        // Autoclose navbar on mobile when route change
        this.router.events.subscribe((val) => {
            // scroll view to top
            window.scrollTo(0, 0);
            // close collapse menu
            this.navCollapsed = true;
        });

        this.inputForm = this.fb.group({
            company: [null]
        })

        this.loadMultiselect();
    }

    showCompanySelect() {
        return this.role == RoleCode.SUPERADMIN;
    }

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        // console.log(stat);
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

    toggleCollapsedSideabar() {
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    toggleFullScreen(event) {
        if (screenfull.enabled) {
            screenfull.toggle();
        }
    }

    // Language
    getLangs() {
        return this.translator.getAvailableLanguages();
    }

    setLang(value) {
        this.translator.useLanguage(value);
        this.selectedLanguage = value;
    }

    loadMultiselect() {
        this.commonService.getMultiselectItems( MultiselectType.COMPANY ).subscribe( resp => {
          this.companyList = resp.data;

          if (localStorage.getItem("idCompany")) {
            this.inputForm.patchValue({ company: this.companyList.find(company => company.id == localStorage.getItem("idCompany")) || null })
          }
        })
    }

    onCompanyChange(company) {
        if (company) {
            localStorage.setItem("idCompany", company.id)
        } else {
            localStorage.removeItem("idCompany")
        }
        
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
        this.router.onSameUrlNavigation = 'reload'
        this.router.navigateByUrl(this.router.url)
        this.router.onSameUrlNavigation = 'ignore'
    }

    // Logout
    logout() {
        console.log("LOGOUT")
        this.settings.sessionExpired();
        this.router.navigate(["/login/0"]);
    }
}
