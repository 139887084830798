import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { S1HttpClientService } from '@app/s1/services/s1-http-client.service';
import { MultiselectType } from '@app/shared/interfaces/common';
import { S1UIService } from '@app/s1';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private s1HttpClient: S1HttpClientService
  ) { }

  getMultiselectItems( multiselectType: MultiselectType ) {
    let endPoint = '';
    switch (multiselectType) {
      case MultiselectType.USERNAME:
        endPoint = MultiselectType.USERNAME;
        break;
      case MultiselectType.EMAIL:
        endPoint = MultiselectType.EMAIL;
        break;
      case MultiselectType.ROLES_TO_CREATION:
        endPoint = MultiselectType.ROLES_TO_CREATION;
        break;
      case MultiselectType.ROLES_TO_FILTER:
        endPoint = MultiselectType.ROLES_TO_FILTER;
        break;
      case MultiselectType.COMPANY:
        endPoint = MultiselectType.COMPANY;
        break;
      case MultiselectType.ATTRIBUTETYPE_STRUCTURE:
        endPoint = MultiselectType.ATTRIBUTETYPE_STRUCTURE;
        break;
      case MultiselectType.TYPE_STRUCTURE:
        endPoint = MultiselectType.TYPE_STRUCTURE;
        break;
      case MultiselectType.CATEGORY_CONTRACT:
        endPoint = MultiselectType.CATEGORY_CONTRACT;
        break;
      case MultiselectType.CONTRACT:
        endPoint = MultiselectType.CONTRACT;
        break;
      case MultiselectType.CHECKLIST_PRIORITY:
        endPoint = MultiselectType.CHECKLIST_PRIORITY;
        break;
      case MultiselectType.CHECKLIST:
        endPoint = MultiselectType.CHECKLIST;
        break;
      case MultiselectType.PLANNING:
        endPoint = MultiselectType.PLANNING;
        break;
      case MultiselectType.HEADQUARTER:
        endPoint = MultiselectType.HEADQUARTER;
        break;
    }

    return this.s1HttpClient.get( '/multiselect/' + endPoint , false ).pipe(
      map( response  => response)
    );
  }

  getVersionBE() {
    return this.s1HttpClient.get('/version');
  }

  int_to_time(duration: number) {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    return {hours, minutes};
  }

  time_to_int(duration: Date) {
    let estimatedDuration = 0;
    if ( duration ) {
      estimatedDuration += (duration.getHours() * 60) + duration.getMinutes();
    }
    return estimatedDuration;
  }

  formatDateToServer( date: Date ) {
    const year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).substring(0, 2);
    if ( date.getMonth() + 1 > 9 ) {
      month = ('' + (date.getMonth() + 1) );
    }
    const day = date.getDate();
    return year + '-' + month + '-' + day;
  }

  formatDateTimeToServer( date: Date, time: Date = null ) {
    if (time != null) {
      date.setHours(time.getHours());
      date.setMinutes(time.getMinutes());
      date.setSeconds(time.getSeconds());
    }
    const year = date.getUTCFullYear();
    let month = ('0' + (date.getUTCMonth() + 1)).substring(0, 2);
    if ( date.getMonth() + 1 > 9 ) {
      month = ('' + (date.getUTCMonth() + 1) );
    }
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  }

  formatDateFromServer( date: any ) {
    return new Date( date );
  }

  /** Returns yyyy-mm-dd */
  formatDateToISO(date: Date | string): string {
    if (typeof(date) === 'string') {
      return new Date(date).toISOString().substring(0, 10);
    } else {
      return date.toISOString().substring(0, 10);
    }
  }

  /** Returns dd/mm/yyyy */
  formatDateToLocale(date: Date | string): string {
    if (typeof(date) === 'string') {
      return new Date(date).toLocaleDateString();
    } else {
      return date.toLocaleDateString();
    }
  }

}
