import { IContratto } from "./contratti";

export interface IUser {
  idUser: number,
  username: string,
  name: string,
  surname: string,
  email: string,
  codeRole: string,
  descRole: string,
  enabled: boolean,
  possibleActions: IPossibleActions,
  company: {
    id: number,
    name?: string
  },
  userContracts
}

export interface IPossibleActions {
  delete: boolean,
  edit: boolean,
}

export interface IUserContracts {
  id: number,
  enabled: boolean,
  contract: IContratto 
}

export interface IResponseUser {
  data: Array<IUser>,
  total: number,
}

export interface IRequestManageUser {
  idUser?: number,
  name: string,
  surname: string,
  email: string,
  enabled: boolean,
  codeRole: string,
}

export interface IUserSort {
  USER?: {
    email?: string,
    username?: string,
    name?: string,
    surname?: string
  }
}

export enum RoleCode {
  SUPERADMIN = "SUPERADMIN",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  RESPONSABILE_CONTRATTO = "RESPONSABILE_CONTRATTO",
  VIEWER_CONTRATTO = "VIEWER_CONTRATTO",
  UTENTE_APP = "UTENTE_APP",
  VIEWER_LIGHT = "VIEWER_LIGHT"
}
