<s1-input-label [label]="label" class="bootstrap-theme">
  <ng-select class="form-control s1"
    [formControl]="control"
    [items]="itemsList"
    [readonly]="readonly"
    [ngClass]="{'is-invalid' : !checkIsValid() }"
    [bindValue]="bindValue"
    [bindLabel]="bindLabel"
    [multiple]="multiple"
    [closeOnSelect]="multiple ? false : true"
    [addTag]="addTag"
    [groupBy]="groupBy"
    [selectableGroup]="selectableGroup"
    [loading]="loading"
    (change)="onChange($event)"
    (remove)="onRemove($event)"
    (clear)="onClear($event)">

    <ng-container *ngIf="groupBy && groupBy != ''">
      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item[groupBy] + '' | uppercase}}
      </ng-template>
    </ng-container>
  
    <ng-container *ngIf="useCheckboxes">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
      </ng-template>
    </ng-container>

    <ng-content></ng-content>

  </ng-select>
</s1-input-label>
