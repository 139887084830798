export interface IRequestBody {
  paging: boolean,
  page: number,
  rows: number,
  orderBy: string,
  direction: string,
  summary: boolean,
  filters?: IFilterObject,
  sorts?: ISortObject,
}

export interface IFilterObject {
  USER?: {
    email: string[],
    username: string[],
    name: string,
    surname: string,
    enabled: boolean
  },
  ROLE?: {
    code: Array<string>
  },
  TYPE_STRUCTURE?: {
    id?: number,
    name?: string,
    isExternal?: boolean,
    enabled?: boolean,
  },
  STRUCTURE?: {
    id?: number[],
    name?: string,
    isExternal?: boolean,
    enabled?: boolean
  },
  ATTRIBUTES?: {
    values?: any[]
  },
  CONTRACT?: {
    name?: string,
    enabled?: boolean,
    id?: number,
  },
  CATEGORY_CONTRACT?: {
    id?: number,
    name?: string,
    enabled?: boolean
  },
  COMPANY?: {
    id?: number
  }
  HEADQUARTER?: {
    id?: number[],
    name?: string,
    address?: string,
    cap?: string,
    city?: string,
    province?: string,
    isExternal?: boolean,
    enabled?: boolean
  },
  CHECKLIST?: {
    id?: number | number[],
    name?: string,
    enabled?: boolean,
    priority?: number,
  },
  PLANNING?: {
    id?: number[],
    name?: string,
    enabled?: boolean
  },
  PLANNING_EXECUTION?: {
    scheduledFrom?: string,
    scheduledTo?: string,
    completed?: boolean,
    executedFrom?: string,
    executedTo?: string,
  },
  OPERATOR?: {
    id: number[]
  },
  PLACE?: {
    id: number[]
  },
  SECTION?: {
    name: string
  };
  REPORT?: {
    dateFrom: string;
    dateTo: string;
  };
}

export interface ISortObject {
  USER?: {
    email?: string,
    username?: string,
    name?: string,
    surname?: string
  },
  TYPE_STRUCTURE?: {
    name?: string,
    description?: string
  },
  STRUCTURE?: {
    name?: string,
    description?: string
  },
  CONTRACT?: {
    name?: string,
    description?: string
  },
  CATEGORY_CONTRACT?: {
    name?: string,
    description?: string
  },
  HEADQUARTER?: {
    name?: string
  },
  CHECKLIST?: {
    name?: string,
    estimatedDuration?: number,
    version?: number,
    priority?: number
  },
  PLANNING?: {
    name?: string
  },
  PLANNING_EXECUTION?: {
    scheduledOn?: string,
    executedOn?: string
  },
}

export interface IRequestStatusBody {
  enabled: boolean
}

// FIXME: Valutare se tenerlo o meno
export interface IFilterObjectUser {
  email: [],
  nome: null,
  cognome: null,
  enabled: null
}

export enum MultiselectType {
  USERNAME = "username",
  EMAIL = "email",
  ROLES_TO_FILTER = "roles_to_filter",
  ROLES_TO_CREATION = "roles_to_creation",
  COMPANY = "company",
  ATTRIBUTETYPE_STRUCTURE = "attributetype_structure",
  TYPE_STRUCTURE = "typestructure",
  CATEGORY_CONTRACT = "category_contract",
  CONTRACT = "contract",
  CHECKLIST_PRIORITY = "checklist_priority",
  CHECKLIST = "checklist",
  PLANNING = "planning",
  HEADQUARTER = "headquarter",
  FILTERS = "filters",
}

export interface IMultiselect {
  id: number,
  name: string
}

export enum EManageType {
  NEW = 1,
  EDIT = 0,
}

export interface IRequestParamFilters {
  name: string;
  section: string;
  params: IRequestBody;
  id?: number;
  idUser?: number;
}
