import { Injectable } from '@angular/core';
import { RoleCode } from '@app/shared/interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class CapabilitiesService {

  private role;
  private capabilities = {
    checklist: {
      canCreateChecklist: true,
      canEditChecklist: true,
      canEnableDisableChecklist: true,
      canResetChecklist: true,
    }
  };

  constructor() { }

  checkRoleCapabilities() {
    this.role = localStorage.getItem('role');
    switch (this.role) {
      case RoleCode.VIEWER_CONTRATTO:
        this.capabilities.checklist.canCreateChecklist = false;
        this.capabilities.checklist.canEditChecklist = false;
        this.capabilities.checklist.canEnableDisableChecklist = false;
        this.capabilities.checklist.canResetChecklist = false;
        break;
      default:
        this.capabilities.checklist.canCreateChecklist = true;
        this.capabilities.checklist.canEditChecklist = true;
        this.capabilities.checklist.canEnableDisableChecklist = true;
        this.capabilities.checklist.canResetChecklist = true;
        break;
    }
  }

  getRoleCapabilities() {
    this.checkRoleCapabilities();
    return this.capabilities;
  }
}
