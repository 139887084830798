import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export interface IS1InputSelectItem {
  code: string | number;
  label: string;
}

@Component({
  selector: 's1-input-select',
  templateUrl: './s1-input-select.component.html',
  styleUrls: ['./s1-input-select.component.scss']
})
export class S1InputSelect implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() label: string = '';
  @Input() readonly: boolean = false;
  @Input() itemsList: IS1InputSelectItem[];
  @Input() multiple: boolean = false;
  @Input() bindValue: string;
  @Input() bindLabel: string;
  @Input() addTag: boolean = false;
  @Input() useCheckboxes: boolean = false;
  @Input() groupBy: string;
  @Input() selectableGroup: boolean = false;
  @Input() loading: boolean = false;
  @Output() change = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();
  @Output() clear = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {

  }

  checkIsValid(): boolean {

    return this.control.valid || !this.control.touched;

  }

  onChange( event ) {
    this.change.emit( event )
  }

  onRemove( event ) {
    this.remove.emit( event )
  }

  onClear( event ) {
    this.clear.emit( event )
  }

}
