import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { IS1PaginationInfo, S1HttpClientService } from '@app/s1/services/s1-http-client.service';

import { IRequestManageUser } from '@app/shared/interfaces/user';
import { IRequestBody, IRequestStatusBody } from '@app/shared/interfaces/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

interface IS1Outcome {
  success: boolean,
  errorMessage: string,
  code?: string
}
interface IS1Response extends HttpResponse<any> {
  outcome: IS1Outcome
  item?: any
  results?: any[]
  paginationInfo?: IS1PaginationInfo
  total?: number
  data?: any
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private path = "/admin/user/list";
  private pathBaseUser = "/admin/user/";
  private pathUpdateStatus = "/admin/user/status/";
  private pathInviteUser = "/admin/user/invite/";
  private pathUserSessionInfo = "/admin/user/user_session/info";
  private pathUserReset = this.pathBaseUser + "reset/";

  constructor( private s1HttpClient: S1HttpClientService ) { }

  getUserList( request: IRequestBody ) {
    return this.s1HttpClient.post( this.path , request);
  }

  getUser( id: number ) {
    return this.s1HttpClient.get( this.pathBaseUser + id );
  }

  newUser( request: IRequestManageUser, showUI: boolean = true ) {
    return this.s1HttpClient.post( this.pathBaseUser , request , showUI ).pipe(
      map( (response : IS1Response ) => response),
      catchError( (error: HttpErrorResponse) => {
        return ( error.status , [error]);
      } )
    );
  }

  updateUser( id: number , request: IRequestManageUser ) {
    return this.s1HttpClient.put( this.pathBaseUser + id, request)
  }

  updateStatus( id: number, request: IRequestStatusBody ) {
    return this.s1HttpClient.put( this.pathUpdateStatus + id, request );
  }

  inviteUser( id: number ) {
    return this.s1HttpClient.put( this.pathInviteUser + id, null );
  }

  deleteUser( id: number ) {
    return this.s1HttpClient.delete( this.pathBaseUser + id );
  }

  getUserSessionInfo() {
    return this.s1HttpClient.get( this.pathUserSessionInfo, false );
  }

  resetUserPasswordToDefault( id: number ) {
    return this.s1HttpClient.put( this.pathUserReset + id, null );
  }

}
