import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";

@Injectable()
export class LoggingService {

  actualLevel: any = 'ALL';
  logLevels = new Array({ 'ALL': 100, 'DEBUG': 200, 'INFO': 300, 'PROD': 400 });

  constructor() {
    if( environment.production ) {
      this.actualLevel = 'PROD';
    }
  }

  log(message: String, obj: any, level: number) {
    if (level >= this.logLevels[0][this.actualLevel]) {
      console.log(message, obj);
    }
  }
}
