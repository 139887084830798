import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { S1ReadableDatePipe } from '../../pipes/s1-readable-date.pipe';

@Component({
  selector: 's1-input-datepicker',
  templateUrl: './s1-input-datepicker.component.html',
  styleUrls: ['./s1-input-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class S1InputDatepicker {

  @Input() control: UntypedFormControl;
  @Input() label = '';
  @Input() readonly = false;
  @Input() maxDate: Date;
  @Input() minDate: Date;

  bsConfig = {
    containerClass: 'theme-dark-blue',
    dateInputFormat: 'DD/MM/YYYY',
    minDate: null,
    maxDate: null
  };

  constructor(private readableDatePipe: S1ReadableDatePipe) { }

  checkIsValid(): boolean {

    // controllo se è stato impostato il maxDate
    if (this.maxDate) {
      this.bsConfig.maxDate = this.maxDate;
    }

    this.bsConfig.minDate = this.minDate;

    return this.control.valid || !this.control.touched;

  }

  readableDate() {
    return this.readableDatePipe.transform(this.control.value);
  }

}
